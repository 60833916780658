<template>
  <div class="the_header flex justify-between items-center">
    <div class="info_compte ">
      <router-link
        class="menu_bottom_element flex"
        to="/"
      >
        <svg-icon
          name="ic-rema"
          class="litee-logo w-48 h-12 ml-6"
          original
        />
      </router-link>
    </div>
    <div
      v-if="showProfile"
      class="main_navbar w-full h-full flex justify-end items-center"
    >
      <div class="notification_container invisible flex justify-center items-center">
        <svg-icon
          name="p_notification"
          class="notification w-6 h-6"
          original
        />
      </div>
      <div
        v-if="!user.adsInfos.isValided"
        class="validate-account flex rounded-lg  p-2 mr-10"
      >
        <div class="flex-full label text p-3 text-white">
          {{ $t('accountNotValidated') }}
        </div>
        <div
          class=" flex-1 p-3 valided-account cursor-pointer font-bold rounded-lg border-none action-button-real text-white text-center"
          @click="sendValidationEmail"
        >
          <svg-icon
            original
            name="ic-look-account"
            class="w-4 h-4"
          />
          {{ $t('validateMyAccount') }}
        </div>
      </div>
      <div class="user_profil_container flex items-center">
        <div class="user_profil">
          <div class="img_container rounded-full w-8 h-8  border ">
            <img
              :src="profilToken"
              class=""
              alt
            >
          </div>
        </div>
        <div class="user_name_role">
          <div
            v-click-outside="hideOption"
            class="user_name flex items-center mt-1"
            @click="changeStateOption"
          >
            <span class="entreprise-name">{{ user.fullname }}</span> <span><svg-icon
              name="ic-down-arrow"
              class="ml-6 mt-3 w-3 h-3"
              fill-current
            /></span>
          </div>
          <!-- <div class="user_role">Admin</div> -->
        </div>
      </div>
    </div>
    <div
      v-if="showOption"
      class="absolute z-10 bg-white option-list p-3 cursor-pointer"
    >
      <p
        class="p-2"
        @click="showProf"
      >
        {{ $t('accountDetails') }}
      </p>
      <p
        class="p-2 text-red cursor-pointer"
        @click="logOut"
      >
        {{ $t('logOut') }}
      </p>
    </div>
    <profil-details
      v-if="showProfilDetails"
      :profil-token="profilToken"
      :user="user"
      @emitCloseProfile="emitCloseProfile"
    />
    <validate-email-sended
      v-if="showSuccessEmail"
      @closeSuccessEmail="closeSuccessEmail"
    />
    <my-custom-loader v-if="showMyCustomLoader" />
  </div>
</template>

<script>
import firebase from 'firebase'
import firebaseStorage from '../rema-function/firebaseStorage'
import ClickOutside from 'vue-click-outside'
/* import firebaseDatabase from '../rema-function/firebaseDatabase' */
import { firebaseDb } from '../main'
import ProfilDetails from './helper/rema-components/profil-details'
import ValidateEmailSended from './helper/rema-components/validate-email-sended'
import axios from 'axios'
import { remaDefaultData } from '../rema-function/remaDefaultData'
import MyCustomLoader from './helper/rema-components/my-custom-loader'
export default {
  name: 'TheHeader',
  components: {
    MyCustomLoader,
    ValidateEmailSended,
    ProfilDetails
  },
  props: {
    showProfile: {
      type: Boolean,
      default: true
    },
    showOptionList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      profilToken: '',
      user: {},
      showProfilDetails: false,
      showSuccessEmail: false,
      showMyCustomLoader: false,
      showOption: false
    }
  },
  computed: {
    showListComp: function () {
      return this.showOptionList
    }
  },
  created () {
    let vm = this
    firebaseStorage.getAvatar(firebase.auth().currentUser.uid).then((url) => {
      if (url) {
      //  alert('trouvé')
        vm.profilToken = url
      } else {
        // alert('e')
        vm.profilToken = ''
      }
    }).catch((err) => {
      // alert('ee')
      vm.profilToken = ''
      console.log(err.toString())
      return ''
    })
  },
  firebase () {
    return {
      user: firebaseDb.ref('users/' + firebase.auth().currentUser.uid)
    }
  },
  methods: {
    logOut () {
      // firebaseDatabase.createCode()
      let vm = this
      firebase.auth().signOut().then(() => {
        vm.$router.replace('/')
      })
        .catch((err) => {
          alert(err.toString())
        })
    },
    changeStateOption () {
      this.showOption = !this.showOption
    },
    hideOption () {
      this.showOption = false
    },
    emitCloseProfile (answer) {
      this.showProfilDetails = false
    },
    showProf () {
      this.showOptionList = false
      this.showProfilDetails = !this.showProfilDetails
    },
    closeSuccessEmail (answer) {
      this.showSuccessEmail = answer
    },
    sendValidationEmail () {
      // alert(this.user.adsInfos.email)
      let vm = this
      this.showMyCustomLoader = true
      let url = remaDefaultData.baseUrl + firebase.auth().currentUser.uid
      const params = new URLSearchParams()
      // alikhaliq66@gmail.com
      params.append('dest', this.user.adsInfos.email)
      params.append('name', this.user.fullname)
      params.append('url', url)
      axios.post(remaDefaultData.emailValidationUrl, params).then((res) => {
        vm.showSuccessEmail = true
        vm.showMyCustomLoader = false
        // alert(JSON.stringify(res))
        // console.log(res)
      })
        .catch((err) => {
          alert(err.toString())
        })
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/style/sass/variables";
.the_header {
  height: 5rem;
  width: 100%;
  border-bottom: solid 1px $border_gray;
  padding: 0 2rem;
  background-color: $white;
  .main_navbar {
    .notification_container {
      // background-color: #fff;
      // box-shadow: 0px 6px 10px rgba(98, 2, 255, 0.05);
      // border-radius: 50px;
      width: 3rem;
      height: 3rem;
      margin: auto 5rem;
      padding-left: 0.3rem;
      cursor: pointer;
      .notification {
        width: 2rem;
        height: 2rem;
        path{
          fill: $red!important;
        }
      }
    }
    .user_profil_container {
      cursor: pointer;
      .user_profil {
        .img_container {
          overflow: hidden;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
        }
      }
      .user_name_role {
        margin: auto 1.2rem;
        line-height: 1.4;
        .user_name {
          font-size: 1.2rem;
          font-weight: 400;
          color: $Dark-Text-Rema;
        }
        .user_role {
          color: $light_gray2;
          font-size: 1.16rem;
        }
      }
    }
  }
  .entreprise-name {
    text-transform: capitalize;
  }
  .option-list {
    margin-left: 85%;
    margin-top: 5.7%;
  }
  .valided-account {
    background-color: $Green-Rema;
  }
  .validate-account {
    background-color: $Rema-Content-Background;
    color: #C4C4C4;
  }
  .label {
    color: #C4C4C4;
  }
}
</style>
