<template>
  <div class="custom-loader">
    <popup-base >
      <div class="loader w-1/2 m-auto">
        <square-loader size="100px"></square-loader>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
import SquareLoader from 'vue-spinner/src/SquareLoader'
export default {
  name: 'MyCustomLoader',
  components: { SquareLoader, PopupBase }
}
</script>

<style scoped>

</style>
