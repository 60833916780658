<template>
  <div class="rema-cropper">
    <popup-base>
      <div class="container rounded-lg w-3/4  crop-big-container bg-white p-2">
       <div class="cropper-container ml-1 p-2 pr-3 w-full h-4/5">
         <clipper-basic
           :src="beforeImage"
           :grid="true"
           ratio="1"
           area=""
           ref="clipper"
           class="h-full w-full"
         />
       </div>
        <div class="flex justify-end mt-4">
          <div class="text-gray-700 text-bold text-center bg-red rounded  px-4 py-2 m-2" @click="cancelCrop">
            <button class="btn px-2 py-1 text-bold font-bold text-white">
              {{ $t('cancel') }}
            </button>
          </div>
          <div class="text-gray-700 text-bold valid-button text-center rounded  px-4 py-2 m-2" @click="imageCropped">
            <button class="btn px-2 py-1 text-bold font-bold text-white">
              {{ $t('validCrop') }}
            </button>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
import ClipperBasic from 'vuejs-clipper/src/components/clipper-basic'
import group from '../../../assets/images/group.svg'
export default {
  name: 'RemaCropper',
  components: { ClipperBasic, PopupBase },
  data () {
    return {
    }
  },
  props: {
    beforeImage: {
      type: String,
      default: group
    }
  },
  methods: {
    imageCropped () {
      let canvas = this.$refs.clipper.clip()
      let img = canvas.toDataURL('image/jpeg', 1)
      this.$emit('imageCropped', img)
    },
    cancelCrop () {
      this.$emit('cancelImageCropped', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .crop-big-container {
   // height: 27rem;
    width: 30rem;
    margin-left: 22%;
  }
  .valid-button {
    background-color: $Green-Rema;
  }
</style>
