import { firebaseStorage } from '../main'
import { storageRef } from './firebaseRef'
import moment from 'moment'

export const userRef = firebaseStorage.ref(storageRef.userInfosRef)
export const adsImagesStorageRef = firebaseStorage.ref(storageRef.adsImagesStorages)
export const adsDraftImagesStorageRef = firebaseStorage.ref(storageRef.adsDraftImagesStorage)
export const adsFilesStorageRef = firebaseStorage.ref(storageRef.adsFilesStorage)
export const chatFilesStorageRef = firebaseStorage.ref(storageRef.chatFileRef)

export default {
  saveNewAvatar (userUid, dataUrl) {
    if (userRef.child(userUid + '/pp').putString(dataUrl, 'data_url')) {
      return true
    } else {
      return false
    }
  },
  getAvatar (userUid) {
    return userRef.child(userUid + '/pp').getDownloadURL()
  },
  savePostImages (key, dataUrl) {
    let generateImgName = key.concat(moment().format('YYYY-MM-DD-HH:mm:ss:Z:SSS'))
    adsImagesStorageRef.child(key + '/' + generateImgName).putString(dataUrl, 'data_url').then((snapshot) => {
      // alert('pusshed images')
    })
      .catch((err) => {
        if (err) {
          console.log(err)
        }
      })
    return generateImgName
  },
  async savePostFile (key, dataUrl) {
    let downloadUrl = ''
    let generateFilename = key.concat(moment().format('YYYY-MM-DD-HH:mm:ss:Z:SSS'))
    await adsFilesStorageRef.child(key + '/' + generateFilename).putString(dataUrl, 'data_url')
      .then((snapshot) => {
        downloadUrl = snapshot.downloadURL
      })
      .catch((err) => {
        if (err) {
          console.log(err)
        }
      })
    return downloadUrl
  },
  savePostImagesInDraft (key, dataUrl) {
    let generateImgName = key.concat(moment().format('YYYY-MM-DD-HH:mm:ss:Z:SSS'))
    adsDraftImagesStorageRef.child(key + '/' + generateImgName).putString(dataUrl, 'data_url').then((snapshot) => {
      // alert('pusshed images')
    })
      .catch((err) => {
        if (err) {
          console.log(err)
        }
      })
    return generateImgName
  },
  getPostImage (postkey, imgKey) {
    return adsImagesStorageRef.child(postkey + '/' + imgKey).getDownloadURL()
  },
  getDraftPostImage (postkey, imgKey) {
    return adsDraftImagesStorageRef.child(postkey + '/' + imgKey).getDownloadURL()
  }
}
