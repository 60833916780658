<template>
  <div
    class="profil-details "
    @click="emitCloseProfile"
  >
    <div
      class="profil-main h-full rounded-lg"
      @click.prevent.stop
      :class="{'is-active-button': disabled === 0 && !wantToChooseDomain && !wantToChooseCountry, 'is-all-active': disabled === 0 && (wantToChooseDomain || wantToChooseCountry)}"
    >
       <div>
         <div class="h-3 just-color rounded-t-lg"></div>
         <div class="content h-full p-4">
           <div class="profile-section flex">
             <div class="flex-one w-1/3">
               <div
                 class="image-section rounded-full cursor-pointer"
                 @click="showSelect"
               >
                 <div
                   v-if=" disabled === 0"
                   class="update-container absolute text-center bg-red rounded-full pt-2 h-10 w-10 z-10 ml-6 mt-6"
                 >
                   <svg-icon
                     name="ic-camera"
                     class="w-6 h-6 "
                     color="#BDBDBD"
                   />
                 </div>

                 <img
                   :src="profilToken"
                   class="rounded-full h-full w-full"
                 >
               </div>
             </div>
             <div class="flex-one w-full">
               <h3 class="name text-xl mt-6 font-bold text-black uppercase">
                 {{ user.fullname }}
               </h3>
               <h3 class="name text-lg mt-1 font-normal ">
                 {{ user.domaine }}
               </h3>
             </div>
           </div>
           <div class="fidder mt-3" />
           <div class="label-title-section mt-4 text-black">
             <h3
               v-if="!wantToUpdate"
               class="icon-section float-right cursor-pointer"
               @click="startUpdate"
             >
               <svg-icon
                 name="ic-edit-profile"
                 class="w-8 h-8"
                 original
               />
             </h3>
             <h3 class="title-section text-2xl font-normal">
               Modifier mon profil
             </h3>
           </div>
           <div class="entrepise-name-section mt-5 ">
             <h3 class="label-text-xl font-light">
               {{ $t('enterpriseName') }}
             </h3>
             <input
               v-model="user.fullname"
               class="mt-2 w-full h-12 rounded border p-2 uppercase"
               :disabled="disabled === 1"
             >
           </div>
           <div class="entrepise-name-section mt-5 ">
             <h3 class="label-text-xl font-light">
               {{ $t('domain') }}
             </h3>
             <div
               v-click-outside="hideOption"
               class="cursor-pointer"
               @click="showDomainList"
             >
               <svg-icon
                 name="ic_select"
                 class="w-4 h-4 mt-4 mr-2 float-right"
                 original
               />
               <h3
                 :class="{'is-disabled': disabled === 1}"
                 class="mt-2 text-black font-normal pt-3 text-sm w-full h-12 rounded border p-2 uppercase"
               >
                 {{ user.domaine }}
               </h3>
               <div
                 v-if="wantToChooseDomain"
                 class="countryList relative border z-10 shadow-md  rounded overflow-y"
               >
                 <h3
                   v-for="(item, key) in domain"
                   :key="item+key"
                   class="p-2 text-lg cursor-pointer font-light text-black"
                   @click="selecDomain(item)"
                 >
                   {{ item }}
                 </h3>
               </div>
             </div>
           </div>
           <div class="entrepise-name-section mt-5 ">
             <h3 class="label-text-xl font-light">
               {{ $t('description') }}
             </h3>
             <textarea
               v-model="user.description"
               class="mt-2 w-full h-24 rounded border p-2 "
               :disabled="disabled === 1"
             />
           </div>
           <div class="entrepise-name-section mt-5 ">
             <h3 class="label-text-xl font-light">
               {{ $t('country') }}
             </h3>
             <div
               v-click-outside="hideOption"
               class="cursor-pointer"
               @click="showCountryList"
             >
               <svg-icon
                 name="ic_select"
                 class="w-4 h-4 mt-4 mr-2 float-right"
                 original
               />
               <h3
                 :class="{'is-disabled': disabled === 1}"
                 class="mt-2 text-black font-normal pt-3  w-full h-12 rounded border p-2 uppercase"
               >
                 {{ user.localisation }}
               </h3>
             </div>
             <div
               v-if="wantToChooseCountry"
               class="countryList relative border z-10 shadow-md  rounded overflow-y"
             >
               <h3
                 v-for="(item, key) in countries"
                 :key="item+key"
                 class="p-2 text-lg cursor-pointer font-light text-black"
                 @click="selectCountry(item)"
               >
                 {{ item.text }}
               </h3>
             </div>
           </div>
           <div class="entrepise-name-section mt-5 ">
             <h3 class="label-text-xl font-light">
               {{ $t('phone') }}
             </h3>
             <input
               v-model="user.tel"
               class="mt-2 w-full h-12 rounded border p-2 uppercase"
               :disabled="disabled === 1"
             >
           </div>
           <div class="entrepise-name-section mt-5 ">
             <h3 class="label-text-xl font-light">
               {{ $t('email') }}
             </h3>
             <input
               v-model="email"
               class="mt-2 w-full h-12 rounded border p-2"
               :disabled="disabled === 1"
             >
           </div>
           <div class="fidder mt-6" />
           <div class="entrepise-name-section mt-5 ">
             <h3 class="label-text-xl font-light">
               {{ $t('olderPassword') }}
             </h3>
             <div class="mt-2 w-full h-12 rounded border">
               <input
                 v-model="oldPass"
                 :type="showOldPassInput"
                 class="h-full p-2 w-full"
                 :disabled="disabled === 1"
               >
               <svg-icon
                 v-if="!wantToSeeOldPass"
                 name="ic-eye-close"
                 class="w-6 h-6 absolute cursor-pointer -ml-8 mt-2 float-right"
                 color="#BEBEBE"
                 original
                 @click="hideOldPass"
               />
               <svg-icon
                 v-if="wantToSeeOldPass"
                 name="ic-eye-open"
                 class="w-6 h-6 cursor-pointer absolute -ml-8 mt-2 float-right"
                 color="#BEBEBE"
                 original
                 @click="showOldPass"
               />
             </div>
           </div>
           <div class="entrepise-name-section mt-5 ">
             <h3 class="label-text-xl font-light">
               {{ $t('newPassword') }}
             </h3>
             <div class="mt-2 w-full h-12 rounded border">
               <input
                 v-model="newPass"
                 :type="showInputPass"
                 class="h-full p-2 w-full"
                 :disabled="disabled === 1"
               >
               <svg-icon
                 v-if="!wantToseePass"
                 name="ic-eye-close"
                 class="w-6 h-6 absolute cursor-pointer -ml-8 mt-2 float-right"
                 color="#BEBEBE"
                 original
                 @click="hidePass"
               />
               <svg-icon
                 v-if="wantToseePass"
                 name="ic-eye-open"
                 class="w-6 h-6 cursor-pointer absolute -ml-8 mt-2 float-right"
                 color="#BEBEBE"
                 original
                 @click="showPass"
               />
             </div>
           </div>
           <div class="entrepise-name-section mt-5 ">
             <h3 class="label-text-xl font-light">
               {{ $t('confirmNewPass') }}
             </h3>
             <div class="mt-2 w-full h-12 rounded border">
               <input
                 v-model="confirmNewPass"
                 :type="showInput"
                 class="h-full p-2 w-full"
                 :disabled="disabled === 1"
               >
               <svg-icon
                 v-if="!wantToseeConfirmPass"
                 name="ic-eye-close"
                 class="w-6 h-6 absolute cursor-pointer -ml-8 mt-2 float-right"
                 color="#BEBEBE"
                 original
                 @click="hideConfPass"
               />
               <svg-icon
                 v-if="wantToseeConfirmPass"
                 name="ic-eye-open"
                 class="w-6 cursor-pointer h-6 absolute -ml-8 mt-2 float-right"
                 color="#BEBEBE"
                 original
                 @click="showConfPass"
               />
             </div>
           </div>
           <div
             v-if="wantToUpdate"
             class="mt-3"
             @click="update"
           >
             <button class="px-3 rounded py-3 text-white text-center bg-green w-full">
               <span v-if="!loading">Enregistrer</span>
               <pulse-loader
                 v-if="loading"
                 color="#ffffff"
               />
             </button>
           </div>
         </div>
       </div>
    </div>
    <rema-cropper
      v-if="wantToCrop"
      :before-image="selectedImageForCrop"
      @imageCropped="CropSucces"
      @cancelImageCropped="cancelImageCropped"
    />
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import RemaCropper from './rema-cropper'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import { firebaseRef } from '../../../rema-function/firebaseRef'
import { firebaseDb } from '../../../main'
import firebase from 'firebase'
import firebaseStorage from '../../../rema-function/firebaseStorage'
import allCountries from '../../../assets/allcountries.json'
export default {
  name: 'ProfilDetails',
  components: { PulseLoader, RemaCropper },
  directives: {
    ClickOutside
  },
  props: {
    user: {
      type: Object,
      default () {
        return {}
      }
    },
    profilToken: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      disabled: 1,
      wantToUpdate: false,
      countries: [],
      domain: [
        'Laboratoire pharmaceutique', 'Agence de représentation pharmaceutique', 'Agence de communication en santé', 'Ministère de santé', 'Santé Numérique', 'Organisation sanitaire', 'Fondation',
        'Association & ONG', 'Matériels biomédicaux', 'Autres'
      ],
      wantToChooseCountry: false,
      wantToChooseDomain: false,
      selectedImageForCrop: '',
      wantToCrop: false,
      loading: false,
      wantToseeConfirmPass: false,
      showInput: 'password',
      wantToseePass: false,
      showInputPass: 'password',
      beforeEmail: '',
      beforePass: '',
      beforeProfilToken: '',
      oldPass: '',
      newPass: '',
      confirmNewPass: '',
      wantToSeeOldPass: false,
      showOldPassInput: 'password',
      email: this.user.adsInfos.email
    }
  },
  created () {
    let vm = this
    var data = allCountries
    data.forEach((val) => {
      if (/^2/.test(val.callingCodes[0])) {
        vm.countries.push({
          name: val.name,
          alpha2Code: val.alpha2Code,
          alpha3Code: val.alpha3Code,
          callingCodes: val.callingCodes[0],
          text: val.translations.fr
        })
      }
    })
    this.beforeEmail = this.user.adsInfos.email
    this.beforePass = this.user.adsInfos.password
    this.beforeProfilToken = this.profilToken
  },
  methods: {
    emitCloseProfile () {
      this.$emit('emitCloseProfile', false)
    },
    startUpdate () {
      this.disabled = 0
      this.wantToUpdate = !this.wantToUpdate
    },
    showCountryList () {
      if (this.disabled === 0) {
        this.wantToChooseCountry = !this.wantToChooseCountry
      }
    },
    selectCountry (item) {
      this.user.localisation = item.name
      this.wantToChooseCountry = false
    },
    hideOption () {
      this.wantToChooseCountry = false
    },
    showDomainList () {
      if (this.disabled === 0) {
        this.wantToChooseDomain = !this.wantToChooseDomain
      }
    },
    selecDomain (item) {
      this.user.domaine = item
    },
    showSelect () {
      if (this.disabled === 0) {
        let input = document.createElement('input')
        input.type = 'file'
        input.setAttribute('multiple', 'multiple')
        input.setAttribute('accept', 'image/*,.pdf')
        // input.setAttribute('accept', ' application/pdf')
        input.onchange = e => {
          let files = e.target.files
          this.createImage(files[0])
        }
        input.setAttribute('capture', 'camera')
        input.click()
      }
    },
    createImage (file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.selectedImageForCrop = e.target.result
        vm.profilToken = e.target.result
      }
      reader.readAsDataURL(file)
      vm.wantToCrop = true
    },
    CropSucces (answer) {
      this.profilToken = answer
      this.wantToCrop = false
    },
    cancelImageCropped () {
      this.wantToCrop = false
    },
    hideConfPass () {
      this.wantToseeConfirmPass = true
      this.showInput = 'text'
    },
    showConfPass () {
      this.wantToseeConfirmPass = false
      this.showInput = 'password'
    },
    hidePass () {
      this.wantToseePass = true
      this.showInputPass = 'text'
    },
    showPass () {
      this.wantToseePass = false
      this.showInputPass = 'password'
    },
    hideOldPass () {
      this.wantToSeeOldPass = true
      this.showOldPassInput = 'text'
    },
    showOldPass () {
      this.wantToSeeOldPass = false
      this.showOldPassInput = 'password'
    },
    update () {
      let vm = this
      this.loading = true
      if (this.email !== this.user.adsInfos.email) {
        this.user.adsInfos.email = this.email
        firebase.auth().currentUser.updateEmail(this.user.adsInfos.email).then((res) => {
          firebaseDb.ref(firebaseRef.users).child(firebase.auth().currentUser.uid).set(vm.user)
        }).catch((err) => {
          alert(err.toString())
        })
      }
      if (this.oldPass !== '' && this.newPass !== '' && this.confirmNewPass !== '') {
        if (this.oldPass === this.user.adsInfos.password) {
          if (this.newPass === this.confirmNewPass) {
            this.user.adsInfos.password = this.newPass
            firebase.auth().currentUser.updatePassword(this.user.adsInfos.password)
              .then((res) => {
                firebaseDb.ref(firebaseRef.users).child(firebase.auth().currentUser.uid).set(this.user)
                // alert('ok')
                this.newPass = ''
                this.oldPass = ''
                this.confirmNewPass = ''
              })
          }
        }
      }
      if (this.beforeProfilToken !== this.profilToken) {
        firebaseStorage.saveNewAvatar(firebase.auth().currentUser.uid, this.profilToken)
      }
      firebaseDb.ref(firebaseRef.users).child(firebase.auth().currentUser.uid).set(this.user)
      window.setTimeout(function () {
        vm.loading = false
        vm.wantToUpdate = false
      }, 8000)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .update-container {
    background-color: rgba(0, 0, 0, 0.39);
    margin-top: 1.8rem;
    margin-left: 1.8rem;
  }
  .profil-details {
    display: flex;
    justify-content: center;
    position: fixed;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.39);
    top: 0;
    left: 0;
    color: rgba(0, 0, 0, 0.3);
    z-index: 50;
    overflow-y: scroll;
    text-align: left;
  }
  .profil-main {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    height: 100%;
    margin-bottom: 50px;
    min-height: 1000px;
    background-color: #fff;
    width: 30%;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  }
  // 900 950 1100
  .is-active-button {
    min-height: 1050px;
  }
  .is-all-active {
    min-height: 1100px;
  }
  .just-color {
    background-color: $Green-Rema;
  }
  .image-section {
    height: 6rem;
    width: 6rem;
  }
  .fidder {
    height: .1rem;
    background-color: $Rema-Content-Background;
  }
  input:disabled {
    background: #F7F9fa;
  }
  textarea:disabled {
    background: #F7F9fa;
  }
  .countryList {
    background-color: white;
    height: 10rem;
    overflow-y: scroll;
  }
  .is-disabled {
    background-color: #F7F9fa;
  }
</style>
